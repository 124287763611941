import React, { useState } from "react"

export default function Question() {
  const [isActive, setIsActive] = useState(false)
  console.log("🚀 ~ file: Question.js ~ line 5 ~ Question ~ isActive", isActive)

  return (
    <div
      className={`qa  ${isActive ? "active" : ""}`}
      onClick={() => setIsActive(p => !p)}
    >
      <div className="question">What goes into an SEO campaign?</div>
      <div className={`answer`}>
        <br />
        <b>1. A keyword strategy</b>
        <br />
        <br />
        Choosing the correct keywords for your website is the cornerstone of
        every SEO campaign, which is why it's the first thing you do with{" "}
        <b>berserkseo</b>.
        <br />
        <br />
        It'll most likely take a few hours to complete in detail, and it should
        be done every 3-6 months.
        <br />
        <br />
        <b>2. A landing page strategy</b>
        <br />
        <br />
        As previously said, this step is critical and should be taken in
        conjunction with any keyword strategy, as new keywords may need the
        building of new pages.
        <br />
        <br />
        This pertains to the <b>berserkseo</b> keyword sitemap section.
        <br />
        <br />
        <b>3. A content strategy</b>
        <br />
        <br />
        It is beneficial to SEO to add new, relevant, and fresh content to the
        website. This can take the form of new blog posts, FAQs, service/product
        pages, and help content, among other things.
        <br />
        <br />
        It's critical to plan ahead of time for the material you'll develop
        throughout the next quarter in order to ensure the strategy's success.
        <br />
        <br />
        <b>4. A 'content' website audit </b>
        <br />
        <br />
        So, once you've decided on keywords, pages, and content, you'll need to
        go over the existing site to check if there are any of the following:
        <br />
        <br />
        1. Material that is relevant to the terms you wish to target (if not,
        you'll need to create fresh content)
        <br />
        2. Existing content that can be reoptimized for new keywords.
        <br />
        3. Any content that has to be 'purged' or updated because it is causing
        performance issues (such as thin content and cannibalising content)
        <br />
        <br />
        <b>5. A 'technical' website audit</b>
        <br />
        <br />
        It's vital that search engines can readily crawl your website; if they
        can't, your fantastic content won't be properly indexed and ranked.
        <br />
        <br />
        Technical SEO is the discipline that ensures this is the case, and you
        must do a technical audit of the website in order to do so. This
        includes things like:
        <br />
        <br />
        - Are there any links on the site that are broken or redirected?
        <br />
        - Are there any pages that haven't been updated in a while?
        <br />
        - Is it possible that duplicate pages are being created?
        <br />
        - How fast is the website?
        <br />
        <br />
        berserkseo will discover technical SEO issues and provide
        recommendations for how to resolve them.
        <br />
        <br />
        <b>6. Competitor analysis</b>
        <br />
        <br />
        A vital and effective SEO technique is to identify your competitors and
        reverse-engineer their success.
        <br />
        <br />
        So what can you look for?
        <br />
        <br />
        1. Take a look at the terms that their competitors rank for (great for
        identifying new keywords)
        <br />
        2. Identify traffic sources that you may not have been aware of, such as
        top referrals (not strictly SEO, but still great for new traffic)
        <br />
        <br />
        <b>7. Link building</b>
        <br />
        <br />
        Any SEO strategy requires active link building. There are a variety of
        link-building activities you can engage in, including:
        <br />
        <br />
        1. Competitor analysis - Analyze your competitors' link-building
        strategies and see what you can learn from them.
        <br />
        2. Directory building - crucial for local businesses, but good link
        building is beneficial for any website that has a physical address.
        <br />
        3. Creative content outreach - You've written an excellent piece of
        content, and now it's time to find related websites to host it and link
        back to you.
        <br />
        <br />
        <b>8. Link audit</b>
        <br />
        <br />
        If you've done SEO with other companies in the past, you should analyze
        your backlink profile to see if there are any potentially unfavorable
        links that are hurting their SEO.
        <br />
        <br />
        <b>9. Mobile audit</b>
        <br />
        <br />
        With the bulk of Google searches now conducted on mobile devices and
        Google's shift to a mobile-first approach, it's more crucial than ever
        to make sure your website is 'mobile-friendly.'
        <br />
        <br />
        To evaluate and track mobile performance, use tools like these:
        <br />
        <br />
        <a
          target="_blank"
          href="https://search.google.com/test/mobile-friendly"
        >
          {" "}
          - <b>Google mobile friendly</b>
        </a>
        <br />
        - Google search console
        <br />
        - Analytics (review traffic to mobile)
        <br />
        <br />
        <b>10. Reporting</b>
        <br />
        <br />
        Easily keep a high level of progress track. This will boost your
        company's performance over time.
        <br />
        <br />
      </div>
    </div>
  )
}
