import React from "react"
import QuestionOne from "./QuestionOne"
import QuestionTwo from "./QuestionTwo"
import QuestionThree from "./QuestionThree"
import QuestionFour from "./QuestionFour"

const questionsAndAnswers = [
  {
    q: "How long does it take to rank for keywords?",
    a: "1",
  },
  {
    q: "2",
    a: "2",
  },
  {
    q: "3",
    a: "3",
  },
]

export default function Questions() {
  return (
    <div className="questions">
      {/* {questionsAndAnswers.map(qa => (
        <Question question={qa.q} answer={qa.a} />
      ))} */}
      <QuestionOne />
      <QuestionTwo />
      <QuestionThree />
      <QuestionFour />
    </div>
  )
}
