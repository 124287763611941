import React, { useState } from "react"
import { Link } from "gatsby"

export default function Question() {
  const [isActive, setIsActive] = useState(false)
  console.log("🚀 ~ file: Question.js ~ line 5 ~ Question ~ isActive", isActive)

  return (
    <div
      className={`qa  ${isActive ? "active" : ""}`}
      onClick={() => setIsActive(p => !p)}
    >
      <div className="question">
        What makes berserkseo different from other SEO tools?
      </div>
      <div className={`answer`}>
        <br />
        Other SEO tools (such as Moz, SEMrush, SERanking, and so on) are useful
        if you already have an SEO strategy in place or know how to do SEO.
        They'll give you data so you can optimize your campaigns. These are
        referred to as data-driven solutions.
        <br />
        <br />
        But what if you aren't an SEO specialist and aren't sure what to do with
        all of the information? Many small firms, as well as non-SEO agencies,
        face this issue. In short, they lack the SEO expertise required to put
        this information to use, and as a result, they are unable to accomplish
        the desired SEO results.
        <br />
        <br />
        berserkseo, on the other hand, does the exact opposite: you create a
        plan, it informs you how to execute it on your website, and it tracks
        your continuous performance.
        <br />
        <br />
        berserkseo is a solution that is built on instructions. You don't need
        to be an SEO expert because it tells you exactly what you need to do.
        <br />
        <br />
        It's ideal if you're new to SEO (as a business owner, part of an
        in-house team, or on the agency side), as it will teach you the ropes.
        <br />
        <br />
      </div>
    </div>
  )
}
