import React, { useEffect } from "react"
import gsap from "gsap"

export default function ActOne() {
  useEffect(() => {
    gsap.set(".faq-heading-letter", { autoAlpha: 1 })
    gsap.set(".subheading", { autoAlpha: 1 })

    gsap.from(".faq-heading-letter", {
      autoAlpha: 0,
      duration: 1,
      ease: "power3.inOut",
      stagger: {
        // from: "center",
        from: "end",
        amount: 0.5,
      },
    })

    gsap.from(".subheading", {
      autoAlpha: 0,
      duration: 1,
      delay: 0.5,
      ease: "power3.inOut",
    })
  }, [])
  return (
    <div className="faq-one">
      <div className="faq-one__heading">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.49 22.63">
          <path
            className="faq-heading-letter"
            d="M406.38 428.66a12.21 12.21 0 0 1-5.59 1.36 11.1 11.1 0 1 1 9-4.19l2.94 3.88h-5.55Zm-5.59-3.29a6.35 6.35 0 0 0 2.64-.56l-3.84-5h5.55l1.42 1.86a7.5 7.5 0 0 0 .59-2.95 6.36 6.36 0 1 0-6.36 6.67Z"
            transform="translate(-356.24 -407.39)"
            id="q"
          />
          <path
            className="faq-heading-letter"
            d="m381.48 407.7 8.4 22h-5.3l-1.24-3.5h-8.93l-1.24 3.5h-5.3l8.4-22Zm.22 14-2.82-7.94-2.82 7.94Z"
            transform="translate(-356.24 -407.39)"
            id="a"
          />
          <path
            className="faq-heading-letter"
            d="M356.24 407.7h12.59v4.46h-7.69v4.4h7.29v4.19h-7.29v9h-4.9Z"
            transform="translate(-356.24 -407.39)"
            id="f"
          />
        </svg>
      </div>
      <div className="faq-one__subheading subheading">
        Frequently asked questions.
      </div>
    </div>
  )
}
