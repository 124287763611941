import React, { useState } from "react"

export default function Question() {
  const [isActive, setIsActive] = useState(false)

  return (
    <div
      className={`qa  ${isActive ? "active" : ""}`}
      onClick={() => setIsActive(p => !p)}
    >
      <div className="question">
        How long will it take take to rank our keywords?
      </div>
      <div className={`answer`}>
        <br />
        It's important to know that SEO takes time.
        <br />
        <br />
        Google's algorithm is designed to reward websites that have established
        a level of 'authority' over time. It does not want to promote websites
        that have not demonstrated that they are deserving of their visitors'
        attention.
        <br />
        <br />
        Some of the factors that determine 'authority' are:
        <br />
        - Age of the website
        <br />
        - The quality of the content
        <br />
        - The quality/quantity of backlinks pointing to that website
        <br />
        - The quality of the website itself (technically-speaking) - Other
        signals (citations, social channels, reviews, etc)
        <br />
        <br />
        Because these accumulate over time, SEO takes time.
        <br />
        <br />
        <b>How long depends on three things:</b>
        <br />
        <br />
        1. The faster you can establish authority based on the aforementioned,
        the better.
        <br />
        2. The level of competition in the SEO market you're pursuing
        <br />
        3. Trying to stay away from anything that Google doesn't like.
        <br />
        <br />
        Let's look at point two: the SEO market's competitiveness. Simply said,
        this indicates how many other websites are actively optimizing for the
        keywords you wish to rank for.
        <br />
        <br />
        There's a common rule of thumb to follow in this situation:
        <br />
        <br />
        The more people that search for a term, the more SEO competitive that
        keyword becomes, and the longer it takes for your site to rank. (With
        this in mind, we constantly encourage small businesses to write blogs,
        as blogs target less competitive keywords and users looking for a
        specific issue, service, or piece of information.)
        <br />
        <br />
        <b>
          {" "}
          So, now that we've covered the basics, let's look at some typical SEO
          timelines:
        </b>
        <br />
        <br />
        If you execute a good blog system you can expect to start driving
        traffic to that blog within *1-2 months*
        <br />
        <br />
        You can anticipate to rank in 3-6 months if you optimize a
        product/service/homepage with a keyword that is low in competition for
        your website*.
        <br />
        <br />
        You can anticipate to rank in 6-12 months if you optimize a
        product/service/homepage with a keyword that is medium in
        competitiveness for your website*.
        <br />
        <br />
        If you optimize a product/service/homepage for a keyword with a high
        level of competition for your website*, you can expect to rank in 12
        months or less.
        <br />
        <br />
        To be clear, everything above is based on previous experience/general
        SEO timeframes, but it all depends on the quality and quantity of SEO
        you execute.
        <br />
        <br />
        It also depends on the amount and quality of SEO work done earlier. They
        are not promised timeframes, especially if you do not do all of the
        berserkseo-provided SEO tasks.
        <br />
        <br />
      </div>
    </div>
  )
}
