import "../styles/faq.scss"
import React from "react"
import ActOne from "../components/FAQ/ActOne"
import Questions from "../components/FAQ/Questions"
import Header from "../components/Header"
import Footer from "../components/Footer"
export default function FAQ() {
  return (
    <div className="faq">
      <Header />
      <ActOne />
      <Questions />
      <Footer />
    </div>
  )
}
