import React, { useState } from "react"
import { Link } from "gatsby"

export default function Question() {
  const [isActive, setIsActive] = useState(false)
  console.log("🚀 ~ file: Question.js ~ line 5 ~ Question ~ isActive", isActive)

  return (
    <div
      className={`qa  ${isActive ? "active" : ""}`}
      onClick={() => setIsActive(p => !p)}
    >
      <div className="question">
        What is the best way to communicate with you?
      </div>
      <div className={`answer`}>
        <br />
        We are available Monday through Friday. Occasionally we're able to
        respond over the weekends as well. Send us a message at{" "}
        <b>hello@berserkers.dev</b> or through our{" "}
        <Link to="/hello">contact form</Link>. We typically respond within 24
        hours.
        <br />
        <br />
      </div>
    </div>
  )
}
